import { render, staticRenderFns } from "./SkillsTicker.vue?vue&type=template&id=7d91d114&scoped=true&"
import script from "./SkillsTicker.vue?vue&type=script&lang=ts&"
export * from "./SkillsTicker.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d91d114",
  null
  
)

export default component.exports