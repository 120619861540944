



























import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
    components: {
        InfiniteScrollBar: () => import('@/helper/InfiniteScroll.vue'),
    },
})
export default class SkillsTicker extends Vue {}
